<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom noprint" v-if="paysips_data != null">
            <h2>{{getMonthName(paysips_data.month)}} {{paysips_data.year}} Payslip</h2>
        </div>
        <nav aria-label="breadcrumb" class="noprint" v-if="paysips_data != null">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{name:'my-payslips'}">My Payslips</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page" >{{getMonthName(paysips_data.month)}} {{paysips_data.year}} Payslip</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>

            <button class="btn btn-primary float-right mb-1" id="print_btn" @click="print">
                <font-awesome-icon icon="download"> </font-awesome-icon>
            </button>

            <table class="table table-bordered table-sm" v-if="paysips_data != null">
                <thead>
                    <tr>
                        <th scope="col" colspan="4" class="text-center">
                            <b-img fluid :src="logo" class="w-25 pl-2"></b-img>
                        </th>
                    </tr>
                    <tr>
                        <th scope="col" colspan="4" class="text-center">
                            Salary Statement for the Month of {{getMonthName(paysips_data.month)}} {{paysips_data.year}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td scope="row">Employee Name</td>
                        <td>{{paysips_data.employee.full_name}}</td>
                        <td>Date of Joining</td>
                        <td>{{dateformat(paysips_data.employee.doj)}}</td>
                    </tr>

                    <tr>
                        <td scope="row">Employee ID</td>
                        <td>{{paysips_data.emp_id}}</td>
                        <td>Bank Account #</td>
                        <td>{{paysips_data.employee.bank_account_number}}</td>
                    </tr>

                    <tr>
                        <td scope="row">Designation</td>
                        <td>{{paysips_data.employee.designation}}</td>
                        <td>UAN Number</td>
                        <td>{{paysips_data.employee.uan}}</td>
                    </tr>

                    <tr>
                        <td scope="row">No of Working Days</td>
                        <td>{{paysips_data.employee_attendance.effective_days_in_the_month}}</td>
                        <td>Holidays Worked</td>
                        <td>{{paysips_data.paid_comp_off_days}}</td>
                    </tr>

                    <tr>
                        <td scope="row">No of Leaves Availed</td>
                        <td>{{paysips_data.employee_attendance.leave_days}}</td>
                        <td>No of Days Worked in Night </td>
                        <td>{{paysips_data.employee_attendance.night_shift_days}}</td>
                    </tr>
                    <tr>
                        <td scope="row">LOP Days</td>
                        <td>{{paysips_data.employee_attendance.lop_days}}</td>
                        <td>No of Days Present</td>
                        <td>{{ paysips_data.present_days }}</td>
                    </tr>

                    <tr>
                        <th scope="row" colspan="2">Earnings</th>
                        <th colspan="2">Deductions</th>
                    </tr>

                    <tr>
                        <th scope="row"></th>
                        <th>Amount(<font-awesome-icon style="font-size:10px" icon="rupee-sign"></font-awesome-icon>)</th>
                        <th></th>
                        <th>Amount(<font-awesome-icon style="font-size:10px" icon="rupee-sign"></font-awesome-icon>)</th>
                    </tr>

                    <tr v-for="(data,index) in earning_deductions" :key="index">
                        <td scope="row">{{ data.hasOwnProperty('earning') ? data['earning']['name'] : '' }}</td>
                        <td>{{ data.hasOwnProperty('earning') ? data['earning']['value']: '' }}</td>
                        <td>{{ data.hasOwnProperty('deduction') ? data['deduction']['name'] : '' }}</td>
                        <td>{{ data.hasOwnProperty('deduction') ? data['deduction']['value'] : '' }}</td>
                    </tr>

                    <tr>
                        <th scope="row">Total Earnings </th>
                        <th><font-awesome-icon style="font-size:12px" icon="rupee-sign"></font-awesome-icon>&nbsp;{{ paysips_data.gross_salary }}</th>
                        <th>Total Deductions</th>
                        <th><font-awesome-icon style="font-size:12px" icon="rupee-sign"></font-awesome-icon>&nbsp;{{ paysips_data.deductions_total }}</th>
                    </tr>

                    <tr>
                        <th scope="row">Net Salary </th>
                        <th><font-awesome-icon style="font-size:12px" icon="rupee-sign"></font-awesome-icon>&nbsp;{{ paysips_data.net_pay }}</th>
                    </tr>
                </tbody>
            </table>

            <div class="justify-content-center" v-else>
                <div class="text-center">
                    <font-awesome-icon icon="spinner" spin size="2x"></font-awesome-icon>
                </div>
            </div>
        </b-container>
        <br>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            api_error: "",
            access: true,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            paysips_data:null,
            employee_name:null,
            earning_deductions: [],
            logo: this.$api.host_url + "images/logo.png",
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_VIEW_MY_PAYSLIPS");
        this.getPayroll();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getMonthName(number){
            let month_number = parseInt(number) -1
            let mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
            return mlist[month_number];
        },

        dateformat (date) {
            var dateFormat = new Date(date);
            var dd = String(dateFormat.getDate()).padStart(2, '0');
            var mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
            var yyyy = dateFormat.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },

        getPayroll() {
            this.paysips_data = null;
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {
                id:this.$route.params.id,
            };
            this.axios.get(this.$api.get_payroll, query).then(response => {
                this.paysips_data = response.data.data;
                this.employee_name=this.paysips_data.employee.full_name;
                document.title = this.employee_name+' [IES-'+this.$route.params.emp_id+'] '+this.getMonthName(this.paysips_data.month)+' '+this.paysips_data.year+' Payslip';
                let earnings = [
                    {
                        name: 'Offered',
                        formula: '',
                        value: this.paysips_data.employee.employee_salary.offered_gross_per_month,
                        from: ''
                    },
                    {
                        name: 'Calculated',
                        formula: '',
                        value: this.paysips_data.calculated_gross_per_month,
                        from: ''
                    }
                ];
                let deductions = [];
                if (this.paysips_data.earnings) {
                    let new_earnings = JSON.parse(this.paysips_data.earnings).filter(earning => earning['value']!=0);
                    earnings = earnings.concat(new_earnings);
                }
                if (this.paysips_data.deductions) {
                    deductions = JSON.parse(this.paysips_data.deductions).filter(deduction => deduction['value']!=0);
                }
                let count = 0;
                if (earnings.length >= deductions.length ) {
                    count = earnings.length;
                } else {
                    count = deductions.length;
                }
                for (let i=0;i<count;i++) {
                    let data = {};
                    if (earnings[i]) {
                        data['earning'] = earnings[i];
                    }
                    if (deductions[i]) {
                        data['deduction'] = deductions[i];
                    }
                    this.earning_deductions.push(data);
                }
                
                this.updateProgressBar(true);
            }).catch(err => {
                console.log(err);
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.paysips_data = null;
                this.showAlert();
            });
        },

        print() {
            window.print();
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
@media print {
    #print_btn {
        display: none;
    }
    .noprint {
        display:none;
    }
    @page {
        margin-top: 0;
        margin-bottom: 0;
    }
    body  {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}
</style>